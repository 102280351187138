const routes = {
  login: {
    index: '/'
  },
  dashboard: {
    index: '/dashboard',
  },
  reports: {
    index: '/reports/list',
    classic: '/reports/classic',
  },
};

export default routes;
