/* eslint-disable */
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import EditableTable from '../components/EditableTable';
import DialogButtons from '../components/DialogButtons';
import mutations from '../services/reports';
import { URL } from '../constants/api';

let typeConfig = 0;
let endpoint_api = 'CustomData';
let idConfig = 0;
let selectorValues = [];
let numberRowsConfig = 5;
let fieldsByCrf = Array(numberRowsConfig).fill([]);
let subFieldsByCrf = Array(numberRowsConfig).fill([]);
let fieldsOrderByCrf = Array(numberRowsConfig).fill([]);
let crfData = Array(numberRowsConfig).fill({});
let crfFieldOrderData = Array(numberRowsConfig).fill({});
let crfFieldData = Array(numberRowsConfig).fill({});
let crfSubFieldData = Array(numberRowsConfig).fill({});
let crfFieldlist = Array(numberRowsConfig).fill([]);
let crfFieldValues = Array(numberRowsConfig).fill([]);
let configTableSchema = Array(numberRowsConfig).fill({});

function useForceUpdate() {
  // eslint-disable-next-line
  const [update, setUpdate] = React.useState(0);
  return () => setUpdate(update => ++update);
}

const textsDialog = {
  titleDelete: 'Delete Item',
  contentDeleteReport:
    'Are you sure to delete the Report? you can´t do it if there are Chart´s',
  contentDeleteChart: 'Are you sure to delete the Chart?',
  buttonNegative: 'Cancel',
  buttonPositive: 'OK'
};

export default function ReportsConfiguration() {
  const forceUpdate = useForceUpdate();
  const [numChart, setNumChart] = React.useState(undefined);
  const [chartSchema, setChartSchema] = React.useState(undefined);
  const [reportSchema, setReportSchema] = React.useState([]);
  const [chartMetaData, setchartMetaData] = React.useState([]);
  const [configReportTitle, setConfigReportTitle] = React.useState({});
  const [Crfs, setCrfs] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogContent, setDialogContent] = React.useState('');
  const [dialogButtonPositive, setDialogButtonPositive] = React.useState('');
  const [dialogButtonNegative, setDialogButtonNegative] = React.useState('');
  const [dialogRow, setDialogRow] = React.useState('');

  const handlerNumChart = num => {
    configTableSchema = Array(numberRowsConfig).fill({});
    setNumChart(num);
    setChartSchema(undefined);
  };

  const getData = () => {
    axios.get(`${URL}/marketQuerys/Charts`).then(response => {
      setchartMetaData(response.data.data);
    });
    axios.get(`${URL}/marketQuerys/Crfs`).then(response => {
      setCrfs(response.data.data);
    });
  };

  const transformConfig = configs => {
    let confiResult = Array(numberRowsConfig);

    [1, 2, 3, 4, 5].forEach((e, i) => {
      confiResult[i] = {};
    });

    if (configs && configs !== '') {
      JSON.parse(configs).data.forEach(conf => {
        const key = Object.keys(conf)[0];
        [1, 2, 3, 4].forEach(n => {
          switch (key) {
          case 'crfName' + n:
            confiResult[n].crf = conf[key];
            break;
          case 'fieldName' + n:
            confiResult[n].field = conf[key];
            break;
          case 'listValues' + n:
            confiResult[n].fieldValues = conf[key];
            break;
          case 'listFields':
            if (
              confiResult[n - 1].crf &&
                !confiResult[n].crf &&
                (!confiResult[n - 1].field ||
                  (confiResult[n - 1].field &&
                    Object.entries(confiResult[n - 1].field).length === 0))
            ) {
              confiResult[n - 1].fieldlist = conf[key].split(';');
            }
            break;
          default:
            break;
          }
        });
      });
    }

    if (confiResult[1] && !confiResult[1].crf)
      confiResult[1].crf = 'c6e4f7574344827a20722c5b6432e51d3'; if (confiResult[1] && !confiResult[1].field) confiResult[1].field = 'f69db3671de4f75970a13f82fded3c2e1';
    [1, 2, 3, 4].forEach(n => {
      confiResult[n].id = n;
      if (confiResult[n] && confiResult[n].crf)
        getAllFieldsCrf(confiResult[n].crf, n);
      if (confiResult[n] && confiResult[n].field)
        getAllSelectorValues(confiResult[n].field, n);
      if (confiResult[n] && confiResult[n].fieldlist) {
        selectorValues[n] = [];
        confiResult[n].fieldlist.map(field => {
          return getAllSelectorValues(field, n);
        });
      }
    });
    return confiResult;
  };

  const getCrsfFieldsReport = (row, type) => {
    let id = row.id;
    idConfig = id;
    endpoint_api = row.endpoint_api;
    typeConfig = type;
    configTableSchema = Array(numberRowsConfig);
    let auxSchema = undefined;
    if (type === 0) auxSchema = reportSchema;
    if (type === 1) auxSchema = chartSchema;
    auxSchema.map(schema => {
      if (id && schema.id && schema.id === id) {
        if (type === 1) {
          setConfigReportTitle(schema.chart_title);
          configTableSchema = transformConfig(schema.chart_config);
        } else {
          setConfigReportTitle(schema.short_description);
          configTableSchema = transformConfig(schema.config);
        }
        [1, 2, 3, 4, 5].forEach(n => {
          if (configTableSchema[n] && configTableSchema[n]['crf']) {
            crfData[n] = configTableSchema[n]['crf'];
            crfFieldData[n] = configTableSchema[n]['field'] || {};
            crfSubFieldData[n] = configTableSchema[n].subField || [];
            crfFieldlist[n] = configTableSchema[n].fieldlist || [];
            crfFieldValues[n] = configTableSchema[n].fieldValues || [];
          } else {
            crfData[n] = {};
            crfSubFieldData[n] = {};
            crfFieldData[n] = {};
            crfFieldlist[n] = [];
            crfFieldValues[n] = [];
          }
        });
        setTimeout(function() {
          forceUpdate();
        }, 1000);
      }

      return;
    });
  };

  const getAllFieldsCrf = (crf, n) => {
    axios
      .all([
        axios.get(`${URL}/marketQuerys/Fields?crf=${crf}`),
        axios.get(`${URL}/marketQuerys/Fields/Type?crf=${crf}&type=6`)
      ])
      .then(responses => {
        const responseOne = responses[0];
        const responseTwo = responses[1];

        fieldsByCrf[n] = responseOne.data.data.fields;
        subFieldsByCrf[n] = responseOne.data.data.subFields;
        fieldsOrderByCrf[n] = responseTwo.data.data;
        forceUpdate();
      })
      .catch(errors => {
        console.log(errors);
      });
  };

  const getAllSelectorValues = (id, n) => {
    axios
      .get(`${URL}/marketQuerys/Fields/metaValueSelection?id=${id}`)
      .then(response => {
        if (!selectorValues[n]) selectorValues[n] = new Array();
        if (response.data.data.value_to_user)
          Object.values(response.data.data.value_to_user).map(element => {
            if (!selectorValues[n].includes(element))
              selectorValues[n].push(element);
          });
        forceUpdate();
      });
  };

  const getAllTypeChart = () => {
    let charts = {};
    chartMetaData.map(meta => {
      charts[meta.id] = meta.short_description;
    });

    return charts;
  };

  const saveReport = row => {
    if (row.id)
      mutations.UpdateReport(
        row.short_description,
        row.endpoint_api,
        row.sort,
        row.parent_report_id,
        row.id
      );
    else
      mutations.NewReport(
        row.short_description,
        'CustomData',
        row.sort,
        row.parent_report_id
      );
    setTimeout(function() {
      setReportSchema([]);
    }, 1000);
  };

  const saveChart = row => {
    if (row.id)
      mutations.UpdateChart(
        row.chart_title,
        row.type,
        row.chart_order,
        row.chart_compose,
        row.reportID,
        row.id
      );
    else
      mutations.NewChart(
        row.chart_title,
        row.type,
        row.chart_order,
        row.chart_compose,
        numChart
      );
    setTimeout(function() {
      setChartSchema(undefined);
    }, 1000);
  };

  const deleteReport = row => {
    setDialogTitle(textsDialog.titleDelete);
    setDialogContent(textsDialog.contentDeleteReport);
    setDialogButtonNegative(textsDialog.buttonNegative);
    setDialogButtonPositive(textsDialog.buttonPositive);
    setDialogRow(row);
    setOpen(true);
  };

  const deleteChart = row => {
    setDialogTitle(textsDialog.titleDelete);
    setDialogContent(textsDialog.contentDeleteChart);
    setDialogButtonNegative(textsDialog.buttonNegative);
    setDialogButtonPositive(textsDialog.buttonPositive);
    setDialogRow(row);
    setOpen(true);
  };

  const saveConfig = row => {
    const data = {
      data: []
    };
    [1, 2, 3, 4, 5].forEach(n => {
      if (
        crfData[n] &&
        (Object.keys(crfData[n]).length !== 0 ||
          !Object.entries(crfData[n]).length === 0)
      )
        data.data.push({ ['crfName' + n]: crfData[n] });

      if (
        crfFieldData[n] &&
        (Object.keys(crfFieldData[n]).length !== 0 ||
          !Object.entries(crfFieldData[n]).length === 0)
      )
        data.data.push({ ['fieldName' + n]: crfFieldData[n] });
      if (
        crfSubFieldData[n] &&
        (Object.keys(crfSubFieldData[n]).length !== 0 ||
          !Object.entries(crfSubFieldData[n]).length === 0)
      )
        data.data.push({ ['subFieldName' + n]: crfSubFieldData[n] });

      if (
        crfFieldOrderData[n] &&
        (Object.keys(crfFieldOrderData[n]).length !== 0 ||
          !Object.entries(crfFieldOrderData[n]).length === 0)
      )
        data.data.push({ ['fieldDate' + n]: crfFieldOrderData[n] });

      if (
        crfFieldlist[n] &&
        (crfFieldlist[n].length > 0 ||
          !Object.entries(crfFieldlist[n]).length === 0)
      )
        data.data.push({ listFields: crfFieldlist[n].join(';') });

      if (
        crfFieldValues[n] &&
        (crfFieldValues[n].length > 0 ||
          !Object.entries(crfFieldValues[n]).length === 0)
      )
        data.data.push({ ['listValues' + n]: crfFieldValues[n].join(';') });
    });

    const Config = JSON.stringify(data);
    if (typeConfig === 1) mutations.UpdateConfChart(Config, idConfig);
    else mutations.UpdateConfReport(Config, idConfig);
    configTableSchema = Array(numberRowsConfig);
    forceUpdate();
  };

  const reportColumns = [
    { title: 'ID', field: 'id', editable: 'never' },
    { title: 'Name', field: 'short_description' },
    {
      title: 'Endpoint',
      field: 'endpoint_api',
      lookup: {
        CustomData: 'CustomData',
        'CustomData/Sankey': 'CustomData/Sankey'
      }
    },
    { title: 'Order', field: 'sort', type: 'numeric' },
    { title: 'Parent ID', field: 'parent_report_id' },
    {
      title: 'Config Report',
      button: 'Config Report',
      render: rowData => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => getCrsfFieldsReport(rowData, 0)}
        >
          Config
        </Button>
      )
    },
    {
      title: 'Show Charts',
      button: 'Charts',
      render: rowData => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handlerNumChart(rowData.id)}
        >
          Charts
        </Button>
      )
    }
  ];

  const chartColumns = [
    { title: 'ID', field: 'id', editable: 'never' },
    { title: 'Name', field: 'chart_title' },
    {
      title: 'Type chart',
      field: 'type',
      lookup: (() => getAllTypeChart())()
    },
    {
      title: 'Report ID',
      field: 'reportID',
      type: 'numeric',
      editable: 'never'
    },
    { title: 'Order', field: 'chart_order', type: 'numeric' },
    {
      title: 'Chart compose',
      field: 'chart_compose',
      lookup: { 0: 'false', 1: 'true' }
    },
    {
      title: 'Config Report',
      button: 'Config Chart',
      render: rowData => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => getCrsfFieldsReport(rowData, 1)}
        >
          Config
        </Button>
      )
    }
  ];

  const handleChangeCrf = (event, i) => {
    if (crfData[i] === event.target.value) crfData[i] = {};
    else {
      crfData[i] = event.target.value;
    }
    getAllFieldsCrf(event.target.value, i);
  };

  const handleChangeField = (event, i) => {
    if (crfFieldData[i] === event.target.value) crfFieldData[i] = {};
    else {
      crfFieldData[i] = event.target.value;
    }
    crfFieldlist[i] = [];
    crfSubFieldData[i] = {};
    getAllSelectorValues(event.target.value, i);
  };
  const handleChangeSubField = (event, i) => {
    if (crfSubFieldData[i] === event.target.value) crfSubFieldData[i] = {};
    else {
      crfSubFieldData[i] = event.target.value;
    }
    crfFieldData[i] = {};
    crfFieldlist[i] = [];
    getAllSelectorValues(event.target.value, i);
  };

  const handleChangeOrderField = (event, i) => {
    if (crfFieldOrderData[i] === event.target.value) crfFieldOrderData[i] = {};
    else {
      crfFieldOrderData[i] = event.target.value;
    }
    forceUpdate();
  };

  const handleChangeFieldlist = (event, i) => {
    crfFieldlist[i] = event.target.value;
    forceUpdate();
  };

  const handleChangeFieldValues = (event, i) => {
    crfFieldValues[i] = event.target.value;
    forceUpdate();
  };

  let SelectorCrf = i => {
    if (i === undefined) return <div></div>;
    return (
      <Select value={crfData[i]} onChange={e => handleChangeCrf(e, i)}>
        {Crfs.map(meta => (
          <MenuItem value={meta.name}>{meta.label}</MenuItem>
        ))}
      </Select>
    );
  };

  let SelectorField = i => {
    if (i === undefined) return <div></div>;
    if (!fieldsByCrf[i]) return <div></div>;
    return (
      <Grid direction="row">
        <Select value={crfFieldData[i]} onChange={e => handleChangeField(e, i)}>
          {fieldsByCrf[i].map((meta, index) => {
            return (
              <MenuItem key={index} value={meta.name}>
                {meta.label}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };

  let SelectorSubField = i => {
    if (i === undefined) return <div></div>;
    if (!subFieldsByCrf[i]) return <div></div>;
    return (
      <Grid direction="row">
        <Select
          value={crfSubFieldData[i]}
          onChange={e => handleChangeSubField(e, i)}
        >
          {subFieldsByCrf[i].map((meta, index) => {
            return (
              <MenuItem key={index} value={meta.name}>
                {meta.label}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };

  let SelectorOrderField = (i, j) => {
    if (j === 2 && i !== 1) return <div></div>;
    if (i === undefined) return <div></div>;
    if (!fieldsOrderByCrf[i]) return <div></div>;
    return (
      <Grid direction="row">
        <Select
          value={crfFieldOrderData[i]}
          onChange={e => handleChangeOrderField(e, i)}
        >
          {fieldsOrderByCrf[i].map((meta, index) => {
            return (
              <MenuItem key={index} value={meta.name}>
                {meta.label}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    );
  };

  let SelectorFieldlist = i => {
    if (!fieldsByCrf[i] || !crfFieldlist[i]) {
      return <div></div>;
    } else if (
      Object.keys(crfData[i + 1]).length !== 0 ||
      Object.keys(crfData[i]).length === 0 ||
      Object.keys(crfFieldData[i]).length !== 0
    ) {
      return <div></div>;
    } else {
      return (
        <Select
          style={{ maxWidth: 250 }}
          value={crfFieldlist[i]}
          multiple={true}
          onChange={e => handleChangeFieldlist(e, i)}
        >
          {fieldsByCrf[i].map(meta => {
            return <MenuItem value={meta.name}>{meta.label}</MenuItem>;
          })}
        </Select>
      );
    }
  };

  let SelectorFieldValues = i => {
    if (!selectorValues[i]) {
      return <div></div>;
    } else {
      if (!Array.isArray(crfFieldValues[i])) {
        let aux = new Array();
        aux.push(crfFieldValues[i]);
        crfFieldValues[i] = aux;
      }

      return (
        <Select
          style={{ maxWidth: 250 }}
          value={crfFieldValues[i]}
          multiple={true}
          onChange={e => handleChangeFieldValues(e, i)}
        >
          {Object.values(selectorValues[i]).map(meta => {
            return <MenuItem value={meta}>{meta}</MenuItem>;
          })}
        </Select>
      );
    }
  };

  const configColumns = [
    { title: 'id', field: 'id', editable: false },
    {
      title: '1.Formulary',
      field: 'crf',
      render: rowData => SelectorCrf(rowData.id)
    },
    {
      title: 'Field Formulary ',
      field: 'field',
      render: rowData => SelectorField(rowData.id)
    },
    {
      title: 'Field Sub Formulary ',
      field: 'subField',
      render: rowData => SelectorSubField(rowData.id)
    },

    {
      title: 'Order by Field',
      field: 'fieldOrder',
      render: rowData => SelectorOrderField(rowData.id, 2)
    },
    {
      title: 'Field values selector ',
      field: 'field',
      render: rowData => SelectorFieldValues(rowData.id)
    },
    {
      title: 'List Field Formulary',
      field: 'fieldlist',
      render: rowData => SelectorFieldlist(rowData.id)
    }
  ];

  const configColumnsSankey = [
    { title: 'id', field: 'id', editable: false },
    {
      title: '1.Formulary',
      field: 'crf',
      render: rowData => SelectorCrf(rowData.id)
    },
    {
      title: 'Field Formulary ',
      field: 'field',
      render: rowData => SelectorField(rowData.id)
    },
    {
      title: 'Order by Field',
      field: 'fieldOrder',
      render: rowData => SelectorOrderField(rowData.id, 1)
    }
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (reportSchema.length === 0)
      axios.get(`${URL}/marketQuerys/Reports`).then(response => {
        let schemaData = response.data.data;
        schemaData.forEach(sch => {
          if (sch.parent_report_id) {
            schemaData.forEach(sch2 => {
              if (sch.parent_report_id === sch2.id)
                if (sch2.subData) sch2.subData.push(sch);
                else sch2.subData = [sch];
            });
          }
        });
        setReportSchema(schemaData);
      });
    if (numChart && !chartSchema) {
      axios
        .get(`${URL}/marketQuerys/SchemaReport?reportID=${numChart}`)
        .then(response => {
          let schemaData = response.data.data;
          schemaData.forEach(sch => {
            if (sch.parent_report_id) {
              schemaData.forEach(sch2 => {
                if (sch.parent_report_id === sch2.id)
                  if (sch2.subData) sch2.subData.push(sch);
                  else sch2.subData = [sch];
              });
            }
          });
          setChartSchema(schemaData);
        });
    }
  }, [reportSchema, chartSchema, numChart]);

  let title = 'Editable Charts';
  let crfIsLoad = undefined;
  if (configTableSchema[1]) {
    crfIsLoad = configTableSchema[1].crf;
  }
  let config = configColumns;
  if (endpoint_api === 'CustomData/Sankey') config = configColumnsSankey;

  return (
    <Grid>
      <DialogButtons
        open={open}
        setOpen={setOpen}
        title={dialogTitle}
        content={dialogContent}
        buttonNegative={dialogButtonNegative}
        buttonPositive={dialogButtonPositive}
        setReportSchema={setReportSchema}
        setChartSchema={setChartSchema}
        dialogCallbackPositive={() => mutations.DeleteRow(dialogRow)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EditableTable
            editable={true}
            deleteRow={row => deleteReport(row)}
            saveRow={row => saveReport(row)}
            title={'Editable reports'}
            columns={reportColumns}
            schema={reportSchema}
            handleChartsTable={handlerNumChart}
          />
        </Grid>
      </Grid>
      {chartSchema ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EditableTable
              editable={true}
              deleteRow={row => deleteChart(row)}
              saveRow={row => saveChart(row)}
              title={title}
              columns={chartColumns}
              schema={chartSchema}
              handleChartsTable={handlerNumChart}
            />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
      {crfIsLoad ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EditableTable
              editable={false}
              saveRow={row => saveConfig(row)}
              title={configReportTitle}
              columns={config}
              schema={configTableSchema}
              handleChartsTable={handlerNumChart}
            />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
    </Grid>
  );
}
