import React from 'react';
import MaterialTable from 'material-table';

export default function EditableTAble(props) {

  const [stateReport, setStateReport] = React.useState({
    columns: [],
    data: [],
  });
  const [lastStateReport, setLastStateReport] = React.useState({
    columns: [],
    data: [],
  });
  if((props.schema && props.schema.length > 0 &&lastStateReport&& lastStateReport.data && lastStateReport.data.length !== props.schema.length) ||
        (props.schema && props.schema.length > 0 && stateReport.columns.length === 0 ) ||
        (lastStateReport.columns !== props.columns)){
    setStateReport({
      columns: props.columns,
      data: props.schema,
    });
    setLastStateReport({
      columns: props.columns,
      data: props.schema,
    });
  }

  if (props.editable)
    return (
      <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <MaterialTable
          title={props.title}
          columns={stateReport.columns}
          data={stateReport.data}
          actions={[
            {
              icon: 'save',
              tooltip: 'Save row',
              onClick: (event, rowData) => props.saveRow(rowData)
            },
            {
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) => props.deleteRow(rowData)
            }
          ]}
          editable={{
            onRowAdd: newData =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  setStateReport(prevState => {
                    const data = [...prevState.data];
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    setStateReport(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
          }}

        />
      </div>
    );
  else
    return (
      <div>
        <MaterialTable
          title={props.title}
          columns={stateReport.columns}
          data={stateReport.data}
          actions={[
            {
              icon: 'save',
              tooltip: 'Save row',
              onClick: (event, rowData) => props.saveRow(rowData)
            }
          ]}

          options={{
            paging:false,
            search:false
          } }

        />
      </div>
    );
}
