import axios from 'axios';
import Cookies from 'js-cookie';
import { URL } from '../constants/api';

export const COOKIE_KEY = 'market_reports';
export const COOKIE_OPTIONS = {
  domain:  window.location.hostname !== 'localhost' ? 'eneida.org' : 'localhost',
}

export const Auth = {
  isAuthenticated: false,
  authenticate(user) {
    const permissions = user.permissions.map(permission => permission.name);
    Auth.isAuthenticated = permissions.includes(
      'core_market_report_view',
      'core_market_report_edit'
    );
    Cookies.set(COOKIE_KEY, {
      token: user.user.token, id: user.user.userId
    }, COOKIE_OPTIONS);
  },
  signout(callback) {
    Auth.isAuthenticated = false;
    Cookies.remove(COOKIE_KEY, COOKIE_OPTIONS);
    callback();
  }
};

function validateLogin(userOrEmail, password) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true'
    }
  };

  return new Promise(async (resolve, reject) => {
    if (!URL) {
      reject('unknown host');
      return;
    }

    const { data : { user, permissions, error } } = await axios
      .post(
        `${URL}/auth/login`,
        {
          username: userOrEmail.toString(),
          password: password.toString()
        },
        options
      );

    if (error) {
      reject(error);
      return;
    }

    const userData = {
      user,
      permissions,
    };

    Auth.authenticate(userData);

    resolve(userData);
  });
}

export const authenticate = async function (endpoint, config) {
  const { token, id } = JSON.parse(Cookies.get(COOKIE_KEY));
  const options = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-User': id,
    }
  };

  if (config) {
    return axios.get(`${URL}/${endpoint}?${config}`, options);
  }

  return axios.get(`${URL}/${endpoint}`, options);
}

export default validateLogin;
