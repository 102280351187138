import React from 'react';
import buildUrl from 'build-url';
import { PERSEED_URL } from '../constants/api';

const styles = {
  iframe: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    border: 'none',
    paddingBottom: '70px',
  }
};

const CLASSIC_REPORTS_URL = process.env.REACT_APP_CLASSIC_REPORTS_URL || false;

export default function ClassicReports() {
  const classicReportsUrl = buildUrl(CLASSIC_REPORTS_URL, {
    queryParams: {
      gateway: PERSEED_URL
    }
  });
  return (
    <iframe title={classicReportsUrl} src={classicReportsUrl} style={styles.iframe} />
  );
}
