import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogButtons(props) {

  const handleClose = () => {
    props.setOpen(false);
  };
  const handleClosePositive = () => {
    if (props.dialogCallbackPositive) {
      props.dialogCallbackPositive();
      //hacer promesa
      setTimeout(function () {
        props.setReportSchema([]);
        props.setChartSchema(undefined);
      },1000);
    }
    props.setOpen(false);
  };

  const handleCloseNegative = () => {
    if (props.dialogCallbackNegative) {
      props.dialogCallbackNegative();
    }
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNegative} color="primary">
            {props.buttonNegative}
          </Button>
          <Button onClick={handleClosePositive} color="primary">
            {props.buttonPositive}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}