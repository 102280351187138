import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Sankey from "../../components/charts/Sankey";
import TableData from './TableData';
import { URL } from '../../constants/api';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const type1Chart = ['1', '2', '3', '4']; //lineal,bar,clumns highchats

export default class Dashboard extends React.Component {
  state = {
    totalByYear: [],
    chartData: null,
    dataChild: undefined,
    xAxis: {},
    series: [],
    chartCompouse: false,
    field1Keys: [],
    loading: false,
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ dataChild: this.props.data }, () => this.dataApiToChart({ data: this.props.data }));
      return;
    };

    if (this.props.schema.chart_config) {
      this.getChildDataChart(this.props);
      return;
    }
  }

  getData = async () => {
    let config = '';
    JSON.parse(this.props.schema.config || '{}').data.forEach(conf => {
      config = `${config}&${Object.keys(conf)[0]}=${conf[Object.keys(conf)[0]]}`;
    });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${URL}/${this.props.schema.endpoint_api}?${config}`
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  getChildDataChart = props => {
    if (props.schema.chart_config) {
      let config = '';
      JSON.parse(props.schema.chart_config).data.forEach(conf => {
        config =
          config + `&${Object.keys(conf)[0]}=${conf[Object.keys(conf)[0]]}`;
      });
      this.setState({ loading: true });
      axios
        .get(
          `${URL}/${props.schema.endpoint_api}?${config}`
        )
        .then(response => {
          this.setState(
            {
              dataChild: response.data.data
            },
            () =>
              this.dataApiToChart({ data: this.state.dataChild })
          );
        }).finally(() => this.setState({ loading: false }));
    }
  }

  dataDeep = data => {
    if (data === undefined || data === null) {
      return 0;
    }

    let deph = 1;
    const field0Keys = Object.keys(data);

    if (field0Keys.length === 0) {
      return 0;
    }

    const field0 = field0Keys[0];
    const field1Keys = Object.keys(data[field0]);
    const field1 = field1Keys[0];
    const field2Keys = Object.keys(data[field0][field1]);
    const field2 = field2Keys[0];

    if (field2Keys.length === 0) {
      return 0;
    }

    const field3Keys = Object.keys(data[field0][field1][field2]);
    if (field0Keys.length > 1)
      if (field3Keys.includes('label')) deph = 2;
      else if (field2Keys.includes('label')) deph = 2;
      else if (field1Keys.includes('label')) deph = 1;
      else deph = 3;

    if (field0Keys.length > 1) deph++;
    return deph;
  }

  dataApiToChart = (rawData) => {
    const data = rawData.data;

    if (!data) {
      return;
    }

    const field0Keys = Object.keys(data);
    let depth = 1;
    if (!data || field0Keys.length === 0) return;
    depth = this.dataDeep(data);
    const field0 = field0Keys[0];
    const field1Keys = Object.keys(data[field0]);
    let xAxis = {};
    var series = new Array(field1Keys.length);


    if (this.props.schema.depth && this.props.schema.depth.split(',').includes(depth.toString())) {
      if (type1Chart.includes(this.props.schema.type)) {
        if (field0Keys.length === 1 && this.props.schema.chart_compose) {
          for (var i = 0; i < field1Keys.length; i++) {
            series[i] = new Array(0);
          }
          field1Keys.forEach((field1, i) => {
            const field2Keys = Object.keys(data[field0][field1]);
            field2Keys.forEach(field2 => {
              let dataAtribute = [];
              const years = Object.keys(data[field0][field1][field2].perYear);
              xAxis = {
                categories: years
              };
              years.forEach(year => {
                dataAtribute.push(
                  data[field0][field1][field2].perYear[year]
                );
              });
              series[i].push({
                name: field2,
                data: dataAtribute
              });
            });
          });
        }

        else if (field0Keys.length === 1 && !this.props.schema.chart_compose) {
          series[0] = new Array(0);
          field1Keys.forEach((field1, i) => {
            const field2Keys = Object.keys(data[field0][field1]);
            field2Keys.forEach(field2 => {
              let dataAtribute = [];
              const years = Object.keys(data[field0][field1][field2].perYear);
              xAxis = {
                categories: years
              };
              years.forEach(year => {
                dataAtribute.push(
                  data[field0][field1][field2].perYear[year]
                );
              });
              series[0].push({
                name: `${field1} (${field2})`,
                data: dataAtribute
              });
            });
          });
        }

        else if (field0Keys.length >= 1 && this.props.schema.chart_compose) {
          for (let i = 0; i < field0Keys.length; i++) {
            series[i] = new Array(0);
          }
          field0Keys.forEach((field0, i) => {
            const field1Keys = Object.keys(data[field0]);
            field1Keys.forEach((field1, k) => {
              const field2Keys = Object.keys(data[field0][field1]);
              field2Keys.forEach((field2, j) => {
                let dataAtribute = [];
                const years = Object.keys(data[field0][field1][field2].perYear);
                xAxis = {
                  categories: years
                };
                years.forEach(year => {
                  dataAtribute.push(
                    data[field0][field1][field2].perYear[year]
                  );
                });
                if (j === 0) {
                  series[i].push({
                    id: field1,
                    name: field2,
                    data: dataAtribute,
                    stack: field1
                  });
                } else {
                  series[i].push({
                    linkedTo: field1,
                    name: field2,
                    data: dataAtribute,
                    stack: field1,
                    states: {
                      hover: {
                        enabled: false
                      }
                    }
                  });
                }
              });
            });
          });
        }


        else if (field0Keys.length >= 1 && !this.props.schema.chart_compose) {
          series = new Array(1).fill([]);
          field0Keys.forEach(field0 => {
            const field1Keys = Object.keys(data[field0]);
            field1Keys.forEach(field1 => {
              const field2Keys = Object.keys(data[field0][field1]);
              let dataAtribute = new Array(
                Object.keys(data[field0][field1][field2Keys[0]].perYear).length
              ).fill(0);
              field2Keys.forEach(field2 => {
                const years = Object.keys(data[field0][field1][field2].perYear);
                xAxis = {
                  categories: years
                };
                years.forEach((year, i) => {
                  dataAtribute[i] =
                    dataAtribute[i] +
                    data[field0][field1][field2].perYear[year];
                });
              });
              series[0].push({
                name: `${field0} (${field1})`,
                data: dataAtribute
              });
            });
          });
        }
      }

      this.setState({
        xAxis,
        series: series,
        field1Keys,
        field0Keys
      });
    }
  }

  render() {
    if (this.props.schema.highcharts) {
      switch (this.props.schema.highcharts) {
        case 'line':
        case 'column': {
          if (this.state.loading) {
            return <CircularProgress />;
          }

          return this.state.series.map((serie, index) => {
            let title = this.props.schema.chart_title;

            if (
              this.state.field0Keys.length > 1 &&
              this.props.schema.chart_compose
            ) {
              title = this.state.field0Keys[index].trim();
            } else if (
              this.state.field0Keys.length > 1 &&
              !this.props.schema.chart_compose
            ) {
              title = `${title} (Total)`;
            } else if (this.state.field1Keys.length > 1 && this.props.schema.chart_compose !== 0) {
              title = `${title} (${this.state.field1Keys[index].trim()})`;
            }

            const cntx = this;
            let chartHeight = 400;
            if (!this.props.schema.chart_compose && serie.length > 10) {
              chartHeight = 800;
            }
            const options = {
              chart: {
                type: this.props.schema.highcharts,
                height: chartHeight
              },
              caption: {
                text: "*Al pulsar sobre los elementos de la leyenda se ocultan o muestran los mismos",
                align: "center"
              },
              title: {
                text: title
              },
              legend: {
                labelFormatter: function () {
                  if (this.userOptions.stack && cntx.props.schema.chart_compose) {
                    return this.userOptions.stack;
                  }

                  if (this.userOptions.stack) {
                    return `${this.name} (${this.userOptions.stack})`;
                  }

                  return this.name;
                }
              },
              tooltip: {
                formatter: function () {
                  var stackName = this.series.userOptions.stack;
                  if (stackName) {
                    if (this.point.stackTotal) {
                      return (
                        '<b>Stack: </b>' +
                        stackName +
                        '<br/>' +
                        this.series.name +
                        ': ' +
                        `${this.y} (${((this.y / this.point.stackTotal) * 100).toFixed(2)}%)` +
                        '<br/>' +
                        'Total: ' +
                        this.point.stackTotal
                      );
                    } else {
                      return (
                        '<b>Stack: </b>' +
                        stackName +
                        '<br/>' +
                        this.series.name +
                        ': ' +
                        this.y
                      );
                    }
                  } else {
                    if (this.point.stackTotal) {
                      return (
                        '<b>' +
                        this.x +
                        '</b><br/>' +
                        this.series.name +
                        ': ' +
                        `${this.y} (${((this.y / this.point.stackTotal) * 100).toFixed(2)}%)` +
                        '<br/>' +
                        'Total: ' +
                        this.point.stackTotal
                      );
                    } else {
                      let total = 0;
                      serie.map((obj) => {
                        total = total + obj.data[this.point.index];
                        return total;
                      });
                      return (
                        '<b>' +
                        this.x +
                        '</b><br/>' +
                        this.series.name +
                        ': ' +
                        `${this.y} (${((this.y / total) * 100).toFixed(2)}%)`
                      );
                    }
                  }
                }
              },
              plotOptions: {
                column: {
                  stacking: 'normal'
                }
              },
              xAxis: this.state.xAxis,
              yAxis: {
                title: {
                  text: 'Número de pacientes'
                }
              },
              series: serie
            };

            return (
              <HighchartsReact
                key={index}
                highcharts={Highcharts}
                options={options}
              />
            );
          });
        }
        case "sankey": {
          let data = []
          if (this.state.dataChild)
            data = this.state.dataChild
          else
            data = this.props.data
          return <Sankey
            endpoint_api={this.props.endpoint_api}
            schema={this.props.schema}
            eliminated={this.props.eliminated}
            years={this.props.years}
            title={this.props.schema.chart_title}
            data={data}
          />;
        }
        default: {
          return <div>Chart not found.</div>;
        }
      }
    }

    // localization
    if (this.state.dataChild && this.props.schema.chart_config) {
      return (
        <TableData
          schema={this.props.schema}
          data={this.state.dataChild}
          totalByYear={this.state.totalByYear}
          eliminated={this.props.eliminated}
          years={this.props.years}
        />
      );
    }

    if (!this.props.schema.chart_config) {
      return (
        <TableData
          schema={this.props.schema}
          totalByYear={this.state.totalByYear}
          data={this.state.chartData}
          eliminated={this.props.eliminated}
          years={this.props.years}
        />
      );
    }

    return <CircularProgress />;
  }
}
