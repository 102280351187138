import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid } from '@material-ui/core';
import Title from '../Title';

const YEARS = [
  2016,
  2017,
  2018,
  2019,
  2020,
];

export default class TableData extends React.Component {
  state = {
    dataChild: undefined,
    xAxis: {},
    rowData: [],
    chartCompouse: false,
    keysFields: [],
    fields: []
  };

  componentDidMount() {
    this.getFieldsAtributes(this.props.data || {});
    this.dataApiToChart(this.props.data || {});
  }

  componentDidUpdate() {
    if (this.state.rowData.length <= 0) {
      this.dataApiToChart(this.props.data);
    }
  }

  componentWillReceiveProps(props) {
    this.getFieldsAtributes(props.data);
    this.dataApiToChart(props.data);
  }

  dataApiToChart = (data) => {
    if (!data) {
      return;
    }

    if (JSON.stringify(data) === '{}') {
      return;
    }

    const field0Keys = Object.keys(data);
    const field0 = field0Keys[0];
    const field1Keys = Object.keys(data[field0]);
    let xAxis = [];
    var rowData = new Array(field1Keys.length);

    if (field0Keys.length === 1) {
      for (var i = 0; i < field1Keys.length; i++) {
        rowData[i] = new Array(0);
      }

      field1Keys.forEach((field1, i) => {
        const field2Keys = Object.keys(data[field0][field1]);
        field2Keys.forEach(field2 => {
          let dataAtribute = [];
          const years = Object.keys(data[field0][field1][field2].perYear);
          xAxis = years;
          years.forEach(year => {
            dataAtribute.push(
              data[field0][field1][field2].perYear[year]
            );
          });
          rowData[i].push({
            name: field2,
            data: dataAtribute
          });
        });
      });
    }

    else if (field0Keys.length >= 1 && this.props.schema.chart_compose) {
      for (let i = 0; i < field0Keys.length; i++) {
        rowData[i] = new Array(0);
      }

      field0Keys.forEach((field0, j) => {
        if (!data[field0][this.state.fields[j]]) return;
        const field2Keys = Object.keys(data[field0][this.state.fields[j]]);
        field2Keys.forEach(field2 => {
          let dataAtribute = [];
          const years = Object.keys(
            data[field0][this.state.fields[j]][field2].perYear
          );
          xAxis = years;
          years.forEach(year => {
            dataAtribute.push(
              data[field0][this.state.fields[j]][field2].perYear[year]
            );
          });
          rowData[j].push({
            name: field2,
            data: dataAtribute
          });
        });
      });
    }

    else if (field0Keys.length >= 1 && !this.props.schema.chart_compose) {
      rowData = new Array(1).fill([]);
      field0Keys.forEach((field0, j) => {
        if (!data[field0][this.state.fields[j]]) return;
        const field2Keys = Object.keys(data[field0][this.state.fields[j]]);
        let dataAtribute = new Array(
          Object.keys(
            data[field0][this.state.fields[j]][field2Keys[0]].perYear
          ).length
        ).fill(0);
        field2Keys.forEach(field2 => {
          const years = Object.keys(
            data[field0][this.state.fields[j]][field2].perYear
          );
          xAxis = years;
          years.forEach((year, i) => {
            dataAtribute[i] =
              dataAtribute[i] +
              data[field0][this.state.fields[j]][field2].perYear[year];
          });
        });
        rowData[0].push({
          name: field0,
          data: dataAtribute
        });
      });
    }

    //Calculado total por columna
    rowData.forEach(rows => {
      let rowTotalColumn = new Array(parseInt(5)).fill(0);
      rows.forEach(row => {
        row.data.forEach((element, j) => {
          rowTotalColumn[j] = rowTotalColumn[j] + element;
        });
      });
      rows.unshift({ name: 'Total pacientes', data: rowTotalColumn });
    });

    //Calculado porcentajes
    if (
      this.state.field0Keys && this.state.field0Keys.length > 1 &&
      !this.props.schema.chart_compose && this.props.totalByYear &&
      this.props.totalByYear.length > 0
    ) {
      rowData.forEach(rows => {
        rows.forEach((row, i) => {
          row.data.forEach((element, j) => {
            const byYear = this.props.totalByYear || [];
            const findTotalByYear = byYear.find(item => parseInt(item.year) === YEARS[j]) || {};
            const total = findTotalByYear.total || 0;

            if (total !== 0) {
              if (!Number.isNaN(element / total))
                row.data[j] = `${element} (${(
                  (element / total) * 100
                ).toFixed(2)}%)`;
            }
          });
        });
      });
    } else {
      rowData.forEach(rows => {
        rows.forEach((row, i) => {
          if (i > 0) {
            row.data.forEach((element, j) => {
              const total = rows[0].data[j];

              if (!Number.isNaN(element / total))
                row.data[j] = `${element} (${(
                  (element / total) * 100
                ).toFixed(2)}%)`;
            });
          }
        });
      });
    }


    this.setState({
      xAxis,
      rowData,
      field1Keys,
      field0Keys
    });
  };

  getFieldsAtributes(data) {
    if (!data) {
      return;
    }

    const field0Keys = Object.keys(data);
    let fields = new Array(field0Keys.length);
    let keysFields = new Array(field0Keys.length);
    field0Keys.map((key, index) => {
      const field1Keys = Object.keys(data[key]);
      keysFields[index] = field1Keys;
      fields[index] = field1Keys[0];
      return fields;
    });

    this.setState({
      keysFields,
      fields
    },
    () => this.dataApiToChart(this.props.data));
  }

  Tables = (serie, index) => {
    let title = this.props.schema.chart_title;
    if (this.state.field0Keys.length > 1 && this.props.schema.chart_compose) {
      title = this.state.field0Keys[index];
    } else if (
      this.state.field0Keys.length > 1 &&
      !this.props.schema.chart_compose
    ) {
      // title = 'Localización de pacientes (Total)';
      // removes total row from SUM type only - in this case
      // the only one using this line is the localization chart
      if (serie.length > 1) {
        serie.shift();
      }
    } else if (this.state.field1Keys.length > 1) {
      title = `${title} (${this.state.field1Keys[index]})`;
    }


    if ((this.state.field1Keys[index] || '').includes('missing')) {
      return (
        <></>
      )
    };

    return (
      <Grid key={index} style={{ paddingBottom: 30 }}>
        <Title style={{ textAlign: 'center' }}>{title}</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><strong>Año</strong></TableCell>
              {this.state.xAxis.map((year, index) => {
                return <TableCell key={index}><strong>{year}</strong></TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {serie.map((row, index) => (
              <TableRow key={index}>
                <TableCell><strong>{row.name}</strong></TableCell>
                {row.data.map((data, j) => {
                  return <TableCell key={j}>{data}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  };

  HeaderTables = props => {
    const j = props.index;
    if (this.state.keysFields[j])
      if (this.state.keysFields.length > 1)
        if (this.state.keysFields[j].length > 1) {
          return (
            <Grid container direction="row"></Grid>
          );
        } else {
          return <div></div>;
        }
    return <div></div>;
  };

  render() {
    if (!this.state.rowData || this.state.rowData.length === 0) {
      return <></>;
    }

    return (
      <Grid>
        {this.state.rowData.map((serie, index) => {
          return (
            <Grid key={index}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <this.HeaderTables index={index} />
              </Grid>
              {this.Tables(serie, index)}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
