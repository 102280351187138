import React from 'react';
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import Layout from './components/Layout';
import ProtectedRoute from './components/route/ProtectedRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ReportsConfiguration from './pages/ReportsConfiguration.js';
import routes from './routes';
import ClassicReports from './pages/ClassicReports';

function App() {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path={routes.login.index}>
          <Login />
        </ProtectedRoute>
        <Layout>
          <ProtectedRoute exact path={routes.dashboard.index}>
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.reports.index}>
            <ReportsConfiguration />
          </ProtectedRoute>
          <ProtectedRoute exact path={routes.reports.classic}>
            <ClassicReports />
          </ProtectedRoute>
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
