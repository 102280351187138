import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import loginService from '../services/login';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issce.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  actionContainer: {
    displa: 'flex',
    justifyContent: 'space-between'
  },
  notchedOutlineError: {
    borderWidth: '1px',
    borderColor: 'red !important'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'blue !important'
  }
}));

export default function Login() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorLogin, setErrorLogin] = React.useState(false);
  const classes = useStyles();

  async function validateLogin(event) {
    event.preventDefault();
    setLoading(true);
    try {
      await loginService(user, password);
      history.push('/dashboard');
    } catch (error) {
      setErrorLogin(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  let textFieldStyle = classes.notchedOutline;
  if (errorLogin) {
    textFieldStyle = classes.notchedOutlineError;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <form className={classes.paper} onSubmit={validateLogin}>
        <Typography component="h1" variant="h5">
          Eneida
        </Typography>
        <TextField
          InputProps={{
            classes: {
              notchedOutline: textFieldStyle
            }
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="username"
          autoComplete="email"
          autoFocus
          value={user}
          disabled={loading}
          onChange={e => {
            setUser(e.target.value);
          }}
        />
        <TextField
          InputProps={{
            classes: {
              notchedOutline: textFieldStyle
            }
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          disabled={loading}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={loading}
        >
          Sign In
        </Button>
        <Grid container className={classes.actionContainer}>
          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </form>
    </Container>
  );
}
