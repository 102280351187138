import axios from 'axios';
import { URL } from '../constants/api';
import { authenticate } from '../services/login';

export async function fetchAvailableReports() {
  return await authenticate(`marketQuerys/reports`);
}

const NewReport = (description, endpoint, orden, parent) => {
  axios.put(`${URL}/marketMutations/AddNewReport?description=${description}&endpoint=${endpoint}&orden=${orden}&parent=${parent}`)
    .then(response => {
      NewChart(description, 1, 1, false, response.data.response.insertId);
    })
    .catch(error => {
      console.log(error);
    });
};

const UpdateReport = (description, endpoint, orden, parent, id) => {
  axios.put(`${URL}/marketMutations/UpdateReport?description=${description}&endpoint=${endpoint}&orden=${orden}&parent=${parent}&id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const UpdateConfReport = (config, id) => {
  axios.put(`${URL}/marketMutations/UpdateReport/Config?config=${config}&id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const NewChart = (description, chartType, orden, chart_compose, reportID) => {
  axios.put(`${URL}/marketMutations/AddNewChart?description=${description}&chartType=${chartType}&orden=${orden}&chart_compose=${chart_compose}&reportID=${reportID}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const UpdateChart = (description, chartType, orden, chart_compose, reportID, id) => {
  axios.put(`${URL}/marketMutations/UpdateChart?description=${description}&chartType=${chartType}&orden=${orden}&chart_compose=${chart_compose}&reportID=${reportID}&id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const UpdateConfChart = (config, id) => {
  axios.put(`${URL}/marketMutations/UpdateChart/Config?config=${config}&id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const DeleteReport = (id) => {
  axios.put(`${URL}/marketMutations/DeleteReport?id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};

const DeleteChart = (id) => {
  axios.put(`${URL}/marketMutations/DeleteChart?id=${id}`)
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
};
const DeleteRow = (row) => {
  if (row.chartID)
    DeleteChart(row.id);
  else
    DeleteReport(row.id);
};

const mutations = {
  UpdateConfChart,
  UpdateChart,
  NewChart,
  UpdateConfReport,
  UpdateReport,
  NewReport,
  DeleteRow,
  DeleteReport,
  DeleteChart,
};

export default mutations;


