import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';
import Cookies from 'js-cookie';
import { Auth, COOKIE_KEY } from '../../services/login.js';
import routes from '../../routes';

export default function ProtectedRoute({ children, ...rest }) {
  Auth.isAuthenticated = Cookies.get(COOKIE_KEY) ? true : false;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (Auth.isAuthenticated && location.pathname === routes.login.index) {
          return <Redirect
            to={{
              pathname: routes.dashboard.index,
              state: { from: location }
            }}
          />
        }

        if (Auth.isAuthenticated && location.pathname !== routes.login.index) {
          return children;
        }

        if (!Auth.isAuthenticated && location.pathname === routes.login.index) {
          return children;
        }

        return <Redirect
          to={{
            pathname: '/',
            state: { from: location }
          }}
        />
      }}
    />
  );
}
