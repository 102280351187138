import React from 'react';
import Highcharts, { isNumber } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/sankey')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class Sankey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  dataApiToChart = (data) => {
    let dataChart = [];
    data.map(element => {
      if (element.group_values !== null) {
        const values = element.group_values.split('|');
        values.map((e, i) => {
          if (i >= 4) {
            return null;
          }

          if (i === 0) {
            return dataChart.push(['pacientes', e + i]);
          }

          return dataChart.push([values[i - 1] + (i - 1), e + i]);
        });
      }

      return null;
    });

    var map = dataChart.reduce(function (prev, cur) {
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {});

    let result = Object.keys(map).map((e) => {
      let array = [
        ...e.split(',')
      ];
      array.push(map[e]);
      return array;
    });

    this.setState(
      {
        data: result
      }
    );
  };

  componentDidMount = () => {
    if (this.props.data && this.props.data.length) {
      this.dataApiToChart(this.props.data);
    }
  };

  render() {
    let options = {
      tooltip: {
        formatter: function () {
          if (this.point.linksTo) {
            let total = 0;
            this.point.linksTo.map((e, i) => {
              total = total + e.weight;
              return total;
            });
            const percentage = ((this.point.sum / total) * 100).toFixed(2);
            return (
              '<b> ' +
              this.key +
              ': ' +
              `${this.point.sum} ${isNumber(percentage) ? `(${percentage}%)` : ''}` +
              '</b> <br/>' +
              this.point.linksTo.map((e, i) => {
                const slicePercentage = ((e.weight / total) * 100).toFixed(2);
                return `${e.from}:  (${slicePercentage}%)`;
              }
              ).join('<br/>')
            );
          } else {
            return (
              `<b> ${this.point.from} >>>>> ${this.point.to} : ${this.point.weight} </b> `
            );
          }
        }
      },
      chart: {
        height: 1000,
      },
      title: {
        text: this.props.schema.chart_title
      },
      accessibility: {
        point: {
          valueDescriptionFormat:
            '{index}. {point.from} to {point.to}, {point.weight}.'
        }
      },
      series: [
        {
          keys: ['from', 'to', 'weight'],
          data: this.state.data,
          type: 'sankey',
          dataLabels: {
            enabled: false
          }

        }
      ]
    };
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'chart'}
          options={options}
          update={false}
        />
      </div>
    );
  }
}
