import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BarChartIcon from '@material-ui/icons/BarChart';
import routes from '../routes';
import { Auth } from '../services/login';

export default function MenuList() {
  const history = useHistory();
  const location = useLocation();

  function logout() {
    Auth.signout(() => redirect(routes.login.index));
  }

  function redirect(pathname) {
    history.replace(pathname);
  }

  function activeRoute(pathname) {
    if (pathname === location.pathname) {
      return 'primary';
    }
    return 'action';
  }

  return (
    <div>
      <ListItem
        button
        onClick={() => redirect(routes.dashboard.index)}
      >
        <ListItemIcon>
          <BarChartIcon color={activeRoute(routes.dashboard.index)} />
        </ListItemIcon>
        <ListItemText primary="General reports" />
      </ListItem>
      <ListItem
        button
        onClick={() => redirect(routes.reports.classic)}
      >
        <ListItemIcon>
          <BarChartIcon color={activeRoute(routes.reports.classic)} />
        </ListItemIcon>
        <ListItemText primary="Custom reports" />
      </ListItem>
      {/* <ListItem button onClick={() => redirect(routes.reports.index)}> */}
      {/*   <ListItemIcon> */}
      {/*     <SettingsIcon color={activeRoute(routes.reports.index)} /> */}
      {/*   </ListItemIcon> */}
      {/*   <ListItemText primary="Create reports" /> */}
      {/* </ListItem> */}
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <MeetingRoomIcon color="action"/>
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </div>
  );
}
