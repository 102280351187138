import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  treeEntry: {
    flexDirection: 'row',
  },
  treeButton: {
    padding: 0
  }
});

export default function ChartList(props) {
  const { schema } = props;
  const classes = useStyles();
  const [active, setActive] = useState(0);

  if (!schema) {
    return (<div></div>);
  }

  const onClick = selectedChart => {
    const id = selectedChart.id;
    if (active !== id) {
      props.handlerDocumentOpen(selectedChart);
      setActive(id);
    }
  };

  const buildSection = (buildSchema = schema) => {
    return buildSchema.map((section, sectionIndex) => {
      const elements = section.charts.map((elem, index) => {
        if (elem.id) {
          //If id exists, use chart view.
          return (
            <TreeItem
              className={classes.treeEntry}
              key={`${index.toString()}-${elem.short_description}`}
              nodeId={`${index.toString()}-${elem.short_description}`}
              label={elem.short_description}
              onClick={() => onClick(elem)}
            />
          );
        } else {
          //If not, create subgroup.
          return (buildSection([elem]));
        }
      });
      return (
        <TreeItem
          className={classes.treeEntry}
          key={`${section.description}-${sectionIndex}`}
          nodeId={`${section.description}-${sectionIndex}`}
          label={section.description}
        >
          {elements}
        </TreeItem>
      );
    });
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {buildSection()}
    </TreeView>
  );
}
