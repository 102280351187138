import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Chart from '../components/charts/Chart';
import ChartList from '../components/ChartList';
import { authenticate } from '../services/login';
import { fetchAvailableReports } from '../services/reports';

class Dashboard extends React.Component {
  state = {
    open: false,
    cumulative: true,
    eliminated: false,
    document: {},
    reportSchema: [],
    chartConfiguration: [],
    loading: false,
    data: null,
  };

  async componentDidMount() {
    const { data } = await fetchAvailableReports();
    this.setState({ reportSchema: data });
  }

  handlerDocumentOpen = document => {
    this.setState(
      {
        document,
        chartConfiguration: [],
      },
      () => this.getChartConfiguration(document)
    );
  };

  getChartConfiguration = async document => {
    if (!document.charts) {
      return;
    }

    let config = '';

    if (document.endpoint_api) {
      JSON.parse(document.chart_config).data.forEach(conf => {
        config = config + `&${Object.keys(conf)[0]}=${conf[Object.keys(conf)[0]]}`;
      });

      this.setState({ loading: true });

      try {
        const response = await authenticate(document.endpoint_api, config);
        this.setState({
          data: response.data.data,
          chartConfiguration: document.charts
        });
      } catch (error) {
        console.error(error)
      } finally {
        this.setState({ loading: false })
      }
    }
  }

  render() {
    const { classes } = this.props;
    const fixedHeightPaper = classes.paper;

    return (
      <Grid className={classes.container}>
        <h1 className={classes.title}>General reports</h1>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={fixedHeightPaper}>
              <div className={this.state.loading ? classes.show : classes.hide}>
                <LinearProgress />
              </div>
              <div className={this.state.loading ? classes.hide : classes.show}>
                <ChartList
                  schema={this.state.reportSchema}
                  handlerDocumentOpen={this.handlerDocumentOpen}
                />
              </div>
            </Paper>
          </Grid>
          {this.state.chartConfiguration.map((schema, index) => {
            return (
              <Grid
                item
                xs={12}
                key={index}
              >
                <Paper className={classes.paper}>
                  <Chart
                    data={this.state.data}
                    document={this.state.document}
                    schema={schema}
                    eliminated={this.state.eliminated}
                    cumulative={this.state.cumulative}
                    years={this.state.years}
                  />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  title: {
    color: '#3f51b5',
  }
});

export default withStyles(styles)(Dashboard);
